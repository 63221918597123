import React, { useState } from "react"
import { ToastContainer } from "react-toastify"

import arrow from "../images/arrow.svg"

import "./Button.scss"

const Button = ({
  children,
  href,
  styleName,
  type,
  toastify,
  disabled,
  notify,
  stateSubmitting,
}) => {
  const imgBool = !!styleName

  const [state, setState] = useState(false)

  if (stateSubmitting) {
    if (state) {
      notify()
      setState(false)
    }
  }

  return (
    <div className="button">
      <button
        className={styleName}
        type={type ? type : "button"}
        disabled={disabled ? disabled : false}
        onClick={() => setState(true)}
      >
        <a href={href}>{children}</a>
        {!imgBool && (
          <img
            src={arrow}
            loading="lazy"
            alt="right arrrow"
            className="button-arrow"
          />
        )}
      </button>
      {toastify && <ToastContainer />}
    </div>
  )
}

export default Button
