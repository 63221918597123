import { graphql, useStaticQuery } from "gatsby"

export const IndexQuery = () => {
  let query = useStaticQuery(
    graphql`
      query IndexQuery {
        allContentfulIndex {
          nodes {
            node_locale
            header {
              logo {
                file {
                  url
                }
              }
              navigationLinks {
                url
                name
              }
              button {
                name
                url
              }
              buttonAndroid {
                url
              }
            }
            home {
              title1stRow
              title2ndRowBlue
              textUnderTitle
              buttonLink {
                url
              }
              backgroundVideo {
                file {
                  url
                  contentType
                }
              }
              backgroundVideoMobile {
                file {
                  url
                  contentType
                }
              }
              backgroundImage {
                file {
                  url
                  contentType
                }
              }
            }
            about {
              sectionTitleBlue
              sectionName
              sectionContent {
                sectionContent
              }
              sectionTitle {
                sectionTitle
              }
              # backgroundVideo {
              #   file {
              #     url
              #    contentType
              #   }
              # }
              backgroundImage {
                file {
                  url
                  contentType
                }
              }
            }
            forGyms {
              sectionName
              sectionTitle {
                sectionTitle
              }
              sectionCard {
                # textOverImage
                text {
                  text
                }
                image {
                  file {
                    url
                  }
                }
                title
              }
            }
            forUsers {
              sectionName
              sectionTitle {
                sectionTitle
              }
              sectionCard {
                title
                text {
                  text
                }
                # textOverImage
                image {
                  file {
                    url
                  }
                }
              }
            }

            policy {
              termsAndConditions {
                termsAndConditions
              }
              cookie {
                cookie
              }
              privacy {
                privacy
              }
            }

            contact {
              sectionName
              sectionTitle {
                sectionTitle
              }
              sectionText {
                sectionText
              }

              backgroundImage {
                file {
                  url
                  contentType
                }
              }
            }
            footer {
              logo {
                file {
                  url
                }
              }
              columnTitle

              column2LeftLinks {
                name
                url
              }
              column2RightLinks {
                name
                url
              }
              column3Links {
                name
                url
              }
            }
            blog {
              node_locale
              title
              article {
                id
                titleAndLink {
                  name
                  url
                }
                image {
                  file {
                    url
                  }
                }
                tags
                tagsInEnglish
                date
                summary {
                  summary
                }
              }
            }
          }
        }
        allContentfulSectionForGyms {
          nodes {
            node_locale
            sectionName
            sectionTitle {
              sectionTitle
            }
            sectionCard {
              # textOverImage
              text {
                text
              }
              image {
                file {
                  url
                }
              }
              title
            }
          }
        }
        allContentfulSectionForUsers {
          nodes {
            node_locale
            sectionName
            sectionTitle {
              sectionTitle
            }
            sectionCard {
              title
              text {
                text
              }
              # textOverImage
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `
  )

  return query
}
