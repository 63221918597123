import React from "react"
import { Link as LinkG } from "gatsby"
import { Link as LinkS } from "react-scroll"

const Link = ({ children, href, className, target, onClick, indexPage }) => {
  return (
    <li>
      {indexPage ? (
        href !== null && href !== undefined && href.includes("#") === true ? (
          <LinkS
            className={className}
            to={href.replace("#", "")}
            smooth={true}
            duration={800}
            offset={
              typeof document !== "undefined" &&
              (document.body.clientWidth >= 1600
                ? -128
                : document.body.clientWidth >= 1440 &&
                  document.body.clientWidth < 1600
                ? -121.67
                : document.body.clientWidth >= 1280 &&
                  document.body.clientWidth < 1440
                ? -102.38
                : document.body.clientWidth >= 1080 &&
                  document.body.clientWidth < 1280
                ? -86.38
                : document.body.clientWidth >= 768 &&
                  document.body.clientWidth < 1080
                ? -82
                : document.body.clientWidth >= 480 &&
                  document.body.clientWidth < 768
                ? -83
                : document.body.clientWidth < 480
                ? -82
                : 0)
            }
            spy={true}
          >
            {children}
          </LinkS>
        ) : (
          <LinkG
            to={href}
            className={className}
            target={target}
            onClick={onClick}
          >
            {children}
          </LinkG>
        )
      ) : (
        <LinkG
          to={`/${href}`}
          className={className}
          target={target}
          onClick={onClick}
        >
          {children}
        </LinkG>
      )}
    </li>
  )
}

export default Link
