import React from "react"

import "./Footer.scss"

const Footer = ({ data }) => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <img className="footer__logo" src={data?.logo?.file.url} alt="logo" />
        <hr className="line" />
        <div className="footer__flex">
          {/* <div className="footer__flex_column">
            <h2 className="footer__flex_column_title">{data?.columnTitle[0]}</h2>
            <ul className="footer__flex_column_list">
              <h2 className="footer__flex_column_list_title">
                {data?.column1Subtitle[0]}
              </h2>
              <a href="" className="footer__flex_column_list_link adress">
                {data?.column1TextTop.column1TextTop}
              </a>
              <br />
              <h2 className="footer__flex_column_list_title">
                {data?.column1Subtitle[1]}
              </h2>
              <a href="" className="footer__flex_column_list_link adress">
                {data?.column1TextBottom.column1TextBottom}
              </a>
            </ul>
          </div> */}
          <div className="footer__flex_column">
            <h2 className="footer__flex_column_title">
              {data?.columnTitle[0]}
            </h2>
            <div className="footer__flex_column_block">
              <ul className="footer__flex_column_block_list footer__flex_column_list">
                {data?.column2LeftLinks.map(({ name, url }) => (
                  <a
                    key={name}
                    href={url}
                    className="footer__flex_column_list_link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {name}
                  </a>
                ))}
              </ul>
              <ul className="footer__flex_column_list">
                {data?.column2RightLinks.map(({ name, url }) => (
                  <a
                    key={name}
                    href={"mailto:" + url}
                    className="footer__flex_column_list_link"
                  >
                    {name}
                  </a>
                ))}
              </ul>
            </div>
          </div>
          <div className="footer__flex_column last">
            <h2 className="footer__flex_column_title">
              {data?.columnTitle[1]}
            </h2>
            <ul className="footer__flex_column_list">
              {data?.column3Links.map(({ name, url }) => (
                <a
                  key={name}
                  href={url}
                  className="footer__flex_column_list_link"
                  target="_blank"
                  rel="noreferrer"
                >
                  {name}
                </a>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
